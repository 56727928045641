import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PomodoroHistoryPage = () => {
  const [history, setHistory] = useState([]);
  const token = localStorage.getItem('token');
  const userId = JSON.parse(atob(token.split('.')[1])).nameid;

  useEffect(() => {
    fetchHistory();
  }, []);

  const fetchHistory = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_FLASHCARDS_API_BASE_URL}/v1/pomodoro/${userId}/history`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setHistory(response.data);
    } catch (error) {
      console.error('Erro ao carregar histórico do Pomodoro:', error);
    }
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100 p-8">
      <h1 className="text-2xl font-bold mb-6">Histórico de Pomodoro</h1>
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        {history.length > 0 ? (
          history.map((session) => (
            <div key={session.id} className="border-b py-4">
              <p><strong>Tipo:</strong> {session.isBreak ? 'Pausa' : 'Trabalho'}</p>
              <p><strong>Duração:</strong> {session.duration} minutos</p>
              <p><strong>Início:</strong> {new Date(session.startTime).toLocaleString()}</p>
              {session.endTime && <p><strong>Término:</strong> {new Date(session.endTime).toLocaleString()}</p>}
            </div>
          ))
        ) : (
          <p>Nenhuma sessão registrada.</p>
        )}
      </div>
    </div>
  );
};

export default PomodoroHistoryPage;
