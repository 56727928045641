import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Login from './pages/loginPage';
import Register from './pages/registerPage';
import DecksPage from './pages/decksPage';
import CardsPage from './pages/cardsPage';
import StudySessionPage from './pages/studySessionPage';
import Header from './components/Header';
import PomodoroPage from './pages/pomodoroPage';
import PomodoroHistoryPage from './pages/pomodoroHistoryPage';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token);
  }, []);

  return (
    <Router>
      <AppRoutes isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
    </Router>
  );
};

const AppRoutes = ({ isAuthenticated, setIsAuthenticated }) => {
  const location = useLocation();

  const showHeader = !['/login', '/register'].includes(location.pathname);

  return (
    <>
      {showHeader && <Header />}
      <Routes>
        <Route path="/" element={isAuthenticated ? <Navigate to="/decks" /> : <Navigate to="/login" />} />
        <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/register" element={<Register setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/decks" element={isAuthenticated ? <DecksPage /> : <Navigate to="/login" />} />
        <Route path="/decks/:deckId/cards" element={isAuthenticated ? <CardsPage /> : <Navigate to="/login" />} />
        <Route path="/decks/:deckId/study" element={isAuthenticated ? <StudySessionPage /> : <Navigate to="/login" />} />
        <Route path="/pomodoro" element={isAuthenticated ? <PomodoroPage /> : <Navigate to="/login" />} />
        <Route path="/pomodoro/history" element={isAuthenticated ? <PomodoroHistoryPage /> : <Navigate to="/login" />} />
      </Routes>
    </>
  );
};

export default App;
