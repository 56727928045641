import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import PomodoroPage from './pomodoroPage';
import PomodoroHistoryPage from './pomodoroHistoryPage';

const StudySessionPage = () => {
  const { deckId } = useParams();
  const [deckName, setDeckName] = useState('');
  const [cards, setCards] = useState([]);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [showBack, setShowBack] = useState(false);
  const [sessionComplete, setSessionComplete] = useState(false);
  const [pomodoroActive, setPomodoroActive] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchDeckDetails();
    fetchCards();
  }, []);

  const fetchDeckDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_FLASHCARDS_API_BASE_URL}/v1/deck/${deckId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDeckName(response.data.name);
    } catch (error) {
      console.error('Erro ao carregar detalhes do deck:', error);
    }
  };

  const fetchCards = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_FLASHCARDS_API_BASE_URL}/v1/card/deck/${deckId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCards(response.data);
    } catch (error) {
      console.error('Erro ao carregar cartões para a sessão de estudo:', error);
    }
  };

  const handleShowBack = () => setShowBack(!showBack);

  const handleEvaluation = (difficulty) => {
    if (currentCardIndex < cards.length - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
      setShowBack(false);
    } else {
      setSessionComplete(true);
    }
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100 p-8 relative">
      <h1 className="text-2xl font-bold mb-6">Sessão de Estudo: {deckName}</h1>
      {sessionComplete ? (
        <p className="text-green-500">Sessão Completa! Bom trabalho!</p>
      ) : (
        <>
          {cards.length > 0 && (
            <div className="bg-white p-4 rounded-lg shadow-md w-full max-w-md mb-8">
              <h2 className="text-xl font-semibold mb-4">Frente do Cartão</h2>
              <p className="text-center">
                {!showBack ? cards[currentCardIndex].frontCard : cards[currentCardIndex].backCard}
              </p>
              <button
                onClick={handleShowBack}
                className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition duration-200 mt-4"
              >
                {showBack ? 'Mostrar Frente' : 'Mostrar Verso'}
              </button>
            </div>
          )}
  
          {showBack && (
            <div className="w-full max-w-md flex justify-around mt-6">
              <button
                onClick={() => handleEvaluation('ruim')}
                className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-200"
              >
                Ruim
              </button>
              <button
                onClick={() => handleEvaluation('medio')}
                className="bg-yellow-500 text-white py-2 px-4 rounded hover:bg-yellow-600 transition duration-200"
              >
                Médio
              </button>
              <button
                onClick={() => handleEvaluation('bom')}
                className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-200"
              >
                Bom
              </button>
            </div>
          )}
        </>
      )}
  
      {/* Botão para ativar o Pomodoro */}
      {!pomodoroActive && (
        <button
          onClick={() => setPomodoroActive(true)}
          className="bg-purple-500 text-white py-2 px-4 rounded hover:bg-purple-600 transition mt-4"
        >
          Iniciar Pomodoro
        </button>
      )}
  
      {/* Componente do Pomodoro */}
      {pomodoroActive && (
        <div
          className="fixed top-1/2 right-4 transform -translate-y-1/2 bg-white p-4 rounded-lg shadow-lg flex flex-col items-center justify-between"
          style={{
            height: '360px',
            width: '240px',
            overflow: 'hidden',
            maxHeight: 'calc(100vh - 32px)',
          }}
        >
          {showHistory ? (
            <PomodoroHistoryPage />
          ) : (
            <>
              <h2 className="text-base font-bold mb-2 text-center">Pomodoro: {deckName}</h2>
              <PomodoroPage />
            </>
          )}
          <div className="flex justify-between gap-2 w-full px-2 mt-4">
            <button
              onClick={() => setShowHistory(!showHistory)}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition text-sm"
            >
              {showHistory ? 'Voltar' : 'Histórico'}
            </button>
            <button
              onClick={() => setPomodoroActive(false)}
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition text-sm"
            >
              Fechar
            </button>
          </div>
        </div>
      )}
    </div>
  );
  
};

export default StudySessionPage;
