import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom'; 
import Modal from 'react-modal';
import { MdEdit, MdDelete } from 'react-icons/md';


Modal.setAppElement('#root');

const CardsPage = () => {
  const { deckId } = useParams(); 
  const [cards, setCards] = useState([]);
  const [frontCard, setFrontCard] = useState('');
  const [backCard, setBackCard] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentCard, setCurrentCard] = useState(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (deckId) {
      fetchCards();
    }
  }, [deckId]);

  const fetchCards = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_FLASHCARDS_API_BASE_URL}/v1/card/deck/${deckId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setCards(response.data);
    } catch (error) {
      console.error('Erro ao carregar cartões:', error);
      setErrorMessage('Erro ao carregar os cartões. Tente novamente mais tarde.');
    }
  };

  const handleCreateCard = async (e) => {
    e.preventDefault();
    try {
      const newCard = { frontCard, backCard, deckId };
      await axios.post(`${process.env.REACT_APP_FLASHCARDS_API_BASE_URL}/v1/card`, newCard, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setFrontCard('');
      setBackCard('');
      fetchCards();
    } catch (error) {
      console.error('Erro ao criar cartão:', error);
      setErrorMessage('Não foi possível criar o cartão. Verifique os dados e tente novamente.');
    }
  };

  const openEditModal = (card) => {
    setCurrentCard(card);
    setFrontCard(card.frontCard);
    setBackCard(card.backCard);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setCurrentCard(null);
    setFrontCard('');
    setBackCard('');
  };

  const handleEditCard = async () => {
    try {
      const updatedCard = { id: currentCard.id, frontCard, backCard, deckId: currentCard.deckId };
      await axios.put(`${process.env.REACT_APP_FLASHCARDS_API_BASE_URL}/v1/card/${currentCard.id}`, updatedCard, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      fetchCards();
      closeEditModal();
    } catch (error) {
      console.error('Erro ao editar cartão:', error);
      setErrorMessage('Não foi possível editar o cartão. Tente novamente mais tarde.');
    }
  };

  const handleDeleteCard = async (cardId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_FLASHCARDS_API_BASE_URL}/v1/card/${cardId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      fetchCards();
    } catch (error) {
      console.error('Erro ao excluir cartão:', error);
      setErrorMessage('Não foi possível excluir o cartão. Tente novamente mais tarde.');
    }
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100 p-8">
      <h1 className="text-2xl font-bold mb-6">Gerenciar Cartões</h1>
      {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
      
      <form onSubmit={handleCreateCard} className="bg-white p-4 rounded-lg shadow-md w-full max-w-md mb-8">
        <h2 className="text-xl font-semibold mb-4">Criar Novo Cartão</h2>
        <div className="mb-4">
          <label className="block text-gray-700">Frente do Cartão</label>
          <input
            type="text"
            value={frontCard}
            onChange={(e) => setFrontCard(e.target.value)}
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Verso do Cartão</label>
          <textarea
            value={backCard}
            onChange={(e) => setBackCard(e.target.value)}
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition duration-200">
          Criar Cartão
        </button>
      </form>

      <div className="w-full max-w-md">
        <h2 className="text-xl font-semibold mb-4">Meus Cartões</h2>
        {cards.map((card) => (
          <div key={card.id} className="bg-white p-4 rounded-lg shadow-md mb-4 flex justify-between items-center">
            <div>
              <h3 className="font-bold">{card.frontCard}</h3>
              <p className="text-sm">{card.backCard}</p>
            </div>
            <div>  
            <button
              onClick={() => openEditModal(card)}
              className="text-yellow-500 hover:text-yellow-700 transition duration-200 mr-2"
            >
              <MdEdit size={20} />
            </button>
            <button
              onClick={() => handleDeleteCard(card.id)}
              className="text-red-500 hover:text-red-700 transition duration-200"
            >
              <MdDelete size={20} />
            </button>
            </div>
          </div>
        ))}
      </div>

      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={closeEditModal}
        className="bg-white p-8 rounded-lg max-w-md mx-auto"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-xl font-semibold mb-4">Editar Cartão</h2>
        <input
          type="text"
          value={frontCard}
          onChange={(e) => setFrontCard(e.target.value)}
          className="w-full p-2 border rounded mb-4"
        />
        <textarea
          value={backCard}
          onChange={(e) => setBackCard(e.target.value)}
          className="w-full p-2 border rounded mb-4"
        />
        <button onClick={handleEditCard} className="bg-yellow-500 text-white py-2 rounded w-full">
          Salvar Alterações
        </button>
        <button onClick={closeEditModal} className="mt-2 text-gray-500 underline">Cancelar</button>
      </Modal>
    </div>
  );
};

export default CardsPage;
