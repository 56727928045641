import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { FiEdit, FiTrash2 } from 'react-icons/fi';

Modal.setAppElement('#root');

const DecksPage = () => {
  const [decks, setDecks] = useState([]);
  const [name, setName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedDeck, setSelectedDeck] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [currentDeck, setCurrentDeck] = useState(null);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    fetchDecks();
  }, []);

  const fetchDecks = async () => {
    try {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      const userId = decodedToken.nameid;
      const response = await axios.get(`${process.env.REACT_APP_FLASHCARDS_API_BASE_URL}/v1/deck/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDecks(response.data);
    } catch (error) {
      console.error('Erro ao carregar decks:', error);
      setErrorMessage('Erro ao carregar os decks. Tente novamente mais tarde.');
    }
  };

  const handleCreateDeck = async (e) => {
    e.preventDefault();
    try {
      const newDeck = { name, userIds: [JSON.parse(atob(token.split('.')[1])).nameid] };
      await axios.post(`${process.env.REACT_APP_FLASHCARDS_API_BASE_URL}/v1/deck`, newDeck, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setName('');
      fetchDecks();
    } catch (error) {
      console.error('Erro ao criar deck:', error);
      setErrorMessage('Não foi possível criar o deck. Verifique os dados e tente novamente.');
    }
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100 p-8">
      <h1 className="text-2xl font-bold mb-6">Gerenciar Decks</h1>
      {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

      <form onSubmit={handleCreateDeck} className="bg-white p-4 rounded-lg shadow-md w-full max-w-md mb-8">
        <h2 className="text-xl font-semibold mb-4">Criar Novo Deck</h2>
        <div className="mb-4">
          <label className="block text-gray-700">Nome do Deck</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition duration-200"
        >
          Criar Deck
        </button>
      </form>

      <div className="w-full max-w-md">
        <h2 className="text-xl font-semibold mb-4">Meus Decks</h2>
        {decks.map((deck) => (
          <div key={deck.id} className="bg-white p-4 rounded-lg shadow-md mb-4 flex justify-between items-center">
            <div>
              <h1 className="font-bold text-lg">{deck.name}</h1>
              <button onClick={() => navigate(`/decks/${deck.id}/study`)} className="text-blue-500 hover:underline ml-4">
                Estudar
              </button>
              <button onClick={() => navigate(`/decks/${deck.id}/cards`)} className="text-blue-500 hover:underline ml-4">
                Gerenciar Cartões
              </button>
            </div>
            <div>
              <button
                onClick={() => navigate(`/pomodoro/history`)}
                className="text-purple-500 hover:underline"
              >
                Histórico do Pomodoro
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DecksPage;
