import React from 'react';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  let userName = 'Usuário';
  if (token) {
    try {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      userName = decodedToken.unique_name || 'Usuário';
    } catch (error) {
      console.error('Erro ao decodificar token:', error);
    }
  }

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <header className="bg-blue-500 text-white py-4 px-8 flex justify-between items-center">
      <div>
        <h1 className="text-xl font-bold">Flash Cards</h1>
      </div>
      <div className="flex items-center">
        <button
          onClick={handleBack}
          className="bg-white text-blue-500 px-4 py-1 rounded mr-4 hover:bg-gray-200 transition"
        >
          Voltar
        </button>
        <span>{userName}</span>
      </div>
    </header>
  );
};

export default Header;
