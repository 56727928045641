import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PomodoroPage = () => {
  const [isRunning, setIsRunning] = useState(false);
  const [timeLeft, setTimeLeft] = useState(1500); 
  const [sessionId, setSessionId] = useState(null);
  const [isBreak, setIsBreak] = useState(false);
  const token = localStorage.getItem('token');
  const userId = JSON.parse(atob(token.split('.')[1])).nameid;

  useEffect(() => {
    let timer;
    if (isRunning && timeLeft > 0) {
      timer = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
    } else if (timeLeft === 0) {
      endSession();
    }
    return () => clearInterval(timer);
  }, [isRunning, timeLeft]);

  const startSession = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_FLASHCARDS_API_BASE_URL}/v1/pomodoro/${userId}/start`,
        isBreak,
        {
          params: { duration: timeLeft / 60 },
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setSessionId(response.data.id);
      setIsRunning(true);
    } catch (error) {
      console.error('Erro ao iniciar a sessão Pomodoro:', error);
    }
  };

  const endSession = async () => {
    if (!sessionId) return;
    try {
      await axios.post(
        `${process.env.REACT_APP_FLASHCARDS_API_BASE_URL}/v1/pomodoro/end/${sessionId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSessionId(null);
      setIsRunning(false);
      setTimeLeft(1500); 
      alert('Sessão concluída!');
    } catch (error) {
      console.error('Erro ao encerrar a sessão Pomodoro:', error);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100 p-8">
      <h1 className="text-2xl font-bold mb-6">Pomodoro</h1>
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md text-center">
        <h2 className="text-xl font-semibold mb-4">{isBreak ? 'Pausa' : 'Trabalho'}</h2>
        <p className="text-3xl font-bold mb-4">{formatTime(timeLeft)}</p>
        <div className="flex justify-around">
          {!isRunning ? (
            <button
              onClick={startSession}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
            >
              Iniciar
            </button>
          ) : (
            <button
              onClick={() => setIsRunning(false)}
              className="bg-yellow-500 text-white py-2 px-4 rounded hover:bg-yellow-600 transition duration-200"
            >
              Pausar
            </button>
          )}
          <button
            onClick={endSession}
            className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-200"
          >
            Encerrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default PomodoroPage;
